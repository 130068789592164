/*
 * See Vuetify customizations:
 *
 * https://vuetifyjs.com/en/customization/sass-variables
 */
@import '../fonts.scss';
$body-font-family: 'Gotham', sans-serif;

/* v-bottom-navigation */
$bottom-nav-btn-min-width: 50px;

/* v-card */
$card-text-letter-spacing: 0;

/* v-tab */
$tabs-item-letter-spacing: 0;
$tabs-item-min-width: 45px;
$tabs-item-padding: 0 12px;

/* Theme */
$material-light: (
  'background': var(--v-shades-white),
  'text': (
    // Use secondary theme color as default text color
    'primary': var(--v-secondary-base),
  ),
);

// Import Vuetify's variables
@import '~vuetify/src/styles/styles.sass';
